import hbg from "../images/Landing_Page_Banner_&_Book_an_Appointment_Section.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="mt-5 block-cta-1 primary-overlay"
        style={{ backgroundImage: `url(${hbg})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <h2 className="mb-3 mt-0 text-white">
                We provide Complete Healing Solution
              </h2>
              <p className="mb-0 text-white lead">
                “Choosing the right physiotherapy provider is important for
                effective treatment and recovery.”
              </p>
            </div>
            <div className="col-lg-4 text-center">
              <h3 className="text-white mb-4">Make an Appointment</h3>
              <p className="mb-0">
                <Link
                  to="tel:+91 9205 546 009"
                  className="btn btn-outline-white text-white btn-md btn-pill px-5 font-weight-bold btn-block"
                >
                  +91 9205 546 009
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="row">
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3 mx-auto">
                  <div className="d-flex">
                    <span
                      className="icon icon-home mr-3"
                      style={{ fontSize: "30px" }}
                    />
                    <h2 className="footer-heading mb-4 pt-1">
                      Visit our Location
                    </h2>
                  </div>
                  <span className="excerpt d-block text-justify">
                    Commercial Shop/Dwelling No. 3, Block B, Ground Floor, Omaxe
                    India Trade Centre, Alpha 2, Greater Noida, U.P.
                  </span>
                </div>
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3 mx-auto">
                  <div className="d-flex">
                    <span
                      className="icon icon-contacts mr-3"
                      style={{ fontSize: "30px" }}
                    />
                    <h2 className="footer-heading mb-4 pt-1">
                      Contact Information
                    </h2>
                  </div>
                  <ul className="list-unstyled">
                    <li className="pb-2">
                      <div className="d-flex">
                        <span
                          className="icon icon-phone mr-3 pt-3"
                          style={{ fontSize: "22px" }}
                        />
                        <span className="excerpt">
                          <a href="tel:+919205546009">+91 9205 546 009</a>
                          <br />
                          <a href="tel:01206027137" className="ml-2">
                            0120 602 71 37
                          </a>
                        </span>
                      </div>
                    </li>
                    <li className="pb-2">
                      <div className="d-flex">
                        <span
                          className="icon icon-envelope mr-3 pt-1"
                          style={{ fontSize: "20px" }}
                        />
                        <span className="excerpt">
                          <a href="mailto:nextgenphysiotherapy@gmail.com">
                            nextgenphysiotherapy@gmail.com
                          </a>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-2 mx-auto">
                  <div className="d-flex">
                    <span
                      className="icon icon-link2 mr-3"
                      style={{ fontSize: "30px" }}
                    />
                    <h2 className="footer-heading mb-4 pt-1">Quick Links</h2>
                  </div>
                  <ul className="list-unstyled pl-3">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link
                        to="/services"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-md-center text-center">
              <h2 className="footer-heading mb-5">Follow Us</h2>
              <a
                href="https://www.instagram.com/nextgenphysiotherapy?igsh=MTJ5amxqaXU4OG1haw=="
                className="pl-3 pr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon-instagram footer-icon" />
              </a>
              <a
                href="https://youtube.com/@NextgenPhysiotherapy?si=jbwoBepaqxg9uMy6"
                className="pl-3 pr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon-youtube-play footer-icon" />
              </a>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 text-md-center text-center">
            <p>
                Copyright © {new Date().getFullYear()} All rights reserved by NextGen Physiotherapy &
                Rehabilitation Center
                <br />
                Designed and Maintained by{" "}
                <Link
                  to="https://www.enropesolutions.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ENROPE SOLUTIONS
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
